import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import FeedIcon from '@mui/icons-material/Feed';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import WcIcon from '@mui/icons-material/Wc';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const NavigationDrawer = () => {
    const history = useHistory();
    const { pathname } = useLocation();

    const isActive = (path) => path === pathname;

    return <Drawer variant="permanent" anchor="left" className="drawer">
        <Toolbar />
        <Divider />
        <List>
            <ListItem selected={isActive('/users')} button onClick={() => history.push('/users')}>
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary={'Usuarios'} />
            </ListItem>
            <ListItem selected={isActive('/providers')} button onClick={() => history.push('/providers')}>
                <ListItemIcon><WcIcon /></ListItemIcon>
                <ListItemText primary={'Prestadores'} />
            </ListItem>
            <ListItem selected={isActive('/card-personal')} button onClick={() => history.push('/card-personal')}>
                <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                <ListItemText primary={'Personales'} />
            </ListItem>
            <ListItem selected={isActive('/products')} button onClick={() => history.push('/products')}>
                <ListItemIcon><FeedIcon /></ListItemIcon>
                <ListItemText primary={'Publicaciones'} />
            </ListItem>
            <ListItem selected={isActive('/subscriptions')} button onClick={() => history.push('/subscriptions')}>
                <ListItemIcon><InsertInvitationIcon /></ListItemIcon>
                <ListItemText primary={'Reservas'} />
            </ListItem>
            <ListItem selected={isActive('/stats')} button onClick={() => history.push('/stats')}>
                <ListItemIcon><QueryStatsIcon /></ListItemIcon>
                <ListItemText primary={'Stats'} />
            </ListItem>
        </List>
    </Drawer>;
};

export default NavigationDrawer;