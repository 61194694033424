import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import AdminService from "../../services/AdminService";
import MultipleSelectChip from "./Language";
import ImageUpload from "./ImageUpload";
import TextField from "@mui/material/TextField";
import {
  Button,
  CircularProgress,
  Container,
  InputLabel,
  Link,
  List,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { useHistory, useParams } from "react-router-dom";
import { labels } from "../../labels";
import AddressPreview from "./AddressPreview";
import PublicationsModal from "../Users/PublicationsModal";
import ColaboratorsModal from "../Users/ColaboratorsModal";

const UserEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      formattedAddress: "",
      address: {
        city: "",
        street: "",
        streetNumber: "",
        floor: "",
        zipCode: "",
        alias: "",
        latitude: 0,
        longitude: 0,
        countryId: 0,
        linkGoogleMaps: "",
      },
    },
  });

  const formValues = watch();

  const [publicationsModalOpen, setPublicationsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [countries, setCountries] = useState([]);
  const [image, setImage] = useState(null);
  const [addresses, setAddresses] = useState([]);

  let autoCompleteGoogle;
  const getCountries = async () => {
    try {
      const resp = await AdminService.getCountries();
      setCountries((await resp?.data) || []);
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = async () => {
    try {
      const resp = await AdminService.getUser(id);
      resp.data.languages = resp.data.languages.map((l) => l.id);
      setUser((await resp?.data) || {});
    } catch (error) {
      console.log(error);
    }
  };

  const getAddresses = async () => {
    try {
      const resp = await AdminService.getUserAddresses(id);
      setAddresses(resp?.data || {});
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostAddress = async () => {
    //console.log('formValue', formValues)
    //console.log('user?.id', user?.id)

    const data = {
      ...formValues.address,
      //userId: user?.id,
    };

    try {
      await AdminService.postAddress(user?.id, data);
      getAddresses();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setUser(null);
    try {
      data.id = parseInt(id);

      data.languages = user.languages;
      await AdminService.updateUser(data);
      if (image) {
        await AdminService.updateAvatar(id, image);
        setImage(null);
      }

      await getUser();
      //history.push('/users');
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlaceChange = async () => {
    let addressObject = autoCompleteGoogle.getPlace();
    let address = addressObject.address_components;
    const countryId =
      countries?.find((country) => country.name === address[7]?.short_name)
        ?.id || 9;
    setValue("address", {
      city: address[4]?.long_name,
      street: address[1]?.long_name,
      streetNumber: address[0]?.long_name,
      zipCode: `"${address[8]?.short_name}"`,
      countryId: +countryId || 0,
      latitude: addressObject?.geometry?.location?.lat(),
      longitude: addressObject?.geometry?.location?.lng(),
      alias: "Domicilio Profesional",
      linkGoogleMaps: addressObject?.url,
    });
    setValue("formattedAddress", addressObject?.formatted_address);
  };

  useEffect(() => {
    // getLanguages();
    getUser();
    getCountries();
    getAddresses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isMounted = true;

    const loadGoogleMaps = () => {
      autoCompleteGoogle = new window.google.maps.places.Autocomplete(document.getElementById("autocomplete"));
      autoCompleteGoogle.addListener("place_changed", handlePlaceChange);
    };

    if (isMounted) {
      getCountries();

      // Esperar a que se cargue la API de Google Maps
      if (window.google && window.google.maps) {
        loadGoogleMaps();
      } else {
        window.onload = loadGoogleMaps;
      }
    }

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    if (formValues?.formattedAddress === "") {
      setValue("address", {
        city: "",
        street: "",
        streetNumber: "",
        floor: "",
        zipCode: "",
        alias: "",
        latitude: 0,
        longitude: 0,
        countryId: 0,
      });
    }
  }, [formValues.formattedAddress]);

  const styles = {
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 10,
      alignItems: "flex-start",
      padding: 5,
      width: "100%",
    },
    input: {
      width: "100%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      //fetchData();
    }
  };

  // Agregar un listener para el evento de scroll al montar el componente
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!user) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Box>
  );

  //console.log("user", user);
  //console.log("address", addresses);

  return (
    <Container>
      <PublicationsModal
        provider={user}
        open={publicationsModalOpen}
        handleClose={() => setPublicationsModalOpen(false)}
      />

      <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
        <ImageUpload setImage={setImage} avatar={user.avatar} image={image} />
        <h3>
          <div>
            Es Prestador: <b>{user.provider ? "SI" : "NO"}</b>
          </div>
          {user.provider && (
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => setPublicationsModalOpen(true)}
            >
              Ver Publicaciones
            </Link>
          )}
        </h3>
        <div style={styles.inputContainer}>
          <InputLabel>Nombre</InputLabel>
          <TextField
            {...register("name")}
            style={styles.input}
            defaultValue={user.name}
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Apellido</InputLabel>
          <TextField
            {...register("lastName")}
            style={styles.input}
            defaultValue={user.lastName}
          />
        </div>

        <div style={styles.inputContainer}>
          <InputLabel>Bio</InputLabel>
          <TextField
            multiline
            {...register("description")}
            style={{ ...styles.input, whiteSpace: "pre-wrap" }}
            defaultValue={user.description}
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Email</InputLabel>
          <TextField
            {...register("email")}
            style={styles.input}
            defaultValue={user.email}
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Profesion</InputLabel>
          <TextField
            {...register("profession")}
            style={styles.input}
            defaultValue={user.profession}
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Teléfono</InputLabel>
          <TextField
            {...register("phone")}
            style={styles.input}
            defaultValue={user.phone}
          />
        </div>
        <div style={styles.inputContainer}>
          <MultipleSelectChip setUser={setUser} user={user} />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Pais</InputLabel>
          <Select
            {...register("country")}
            style={styles.input}
            defaultValue={user.country}
          >
            {countries.map((country) => (
              <MenuItem key={country.name} value={country.name}>
                {labels.countries[country.name]}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Ciudad</InputLabel>
          <TextField
            {...register("city")}
            style={styles.input}
            defaultValue={user.city}
          />
        </div>

        <Box style={{ marginTop: 10, fontWeight: "bold" }}>Redes Sociales</Box>
        <div style={styles.inputContainer}>
          <InputLabel>Facebook</InputLabel>
          <TextField
            {...register("facebook")}
            style={styles.input}
            defaultValue={user.facebook}
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Instagram</InputLabel>
          <TextField
            {...register("instagram")}
            style={styles.input}
            defaultValue={user.instagram}
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Linkedin</InputLabel>
          <TextField
            {...register("linkedin")}
            style={styles.input}
            defaultValue={user.linkedin}
          />
        </div>

        <Box
          style={{
            marginTop: 10,
            fontWeight: "bold",
            textAlign: "left",
            width: "100%",
          }}
        >
          Google Calendar: {user.googleCalendarLinked ? "SI" : "NO"}
        </Box>

        <Box style={{ marginTop: 10, fontWeight: "bold" }}>Medios de Pago</Box>
        <div style={styles.inputContainer}>
          <InputLabel>Cuenta de Paypal</InputLabel>
          <TextField
            {...register("paypalAccount")}
            style={styles.input}
            defaultValue={user.paypalAccount}
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Cuenta de MercadoPago</InputLabel>
          <TextField
            {...register("mercadoPagoAccount")}
            style={styles.input}
            defaultValue={user.mercadoPagoAccount}
          />
        </div>

        <Box style={{ marginTop: 10, fontWeight: "bold" }}>Direcciones</Box>
        <div style={styles.inputContainer}>
          <InputLabel>Direccion</InputLabel>
          <Stack direction="row"
            sx={{
              width: "100%",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          <TextField
            {...register("formattedAddress")}
            id={"autocomplete"}
            style={styles.input}
            
            defaultValue={formValues.formattedAddress}
            //defaultValue={user.lastName}
          />
          <Button
            variant="contained"
            onClick={handlePostAddress}
            style={{ height: 55}}
          >
            Crear
          </Button>
          </Stack>
        </div>
        <Box>
          <List
            sx={{
              //overflowY: "scroll",
              //maxHeight: 500,
              //backgroundColor: "red",
              flex: 1,
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: 2,
              gap: 2,
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {addresses.map((a) => (
              <AddressPreview
                address={a}
                idUser={user?.id}
                getAddresses={getAddresses}
              />
            ))}
          </List>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: 400,
            marginBottom: 40,
            marginTop: 20,
          }}
        >
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() => history.push("/users")}
          >
            Cancelar
          </Button>
          <Button variant="contained" type="submit" disabled={loading}>
            {loading ? <CircularProgress /> : "Guardar"}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default UserEdit;
