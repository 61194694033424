import { useAuth0 } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';
import { Router, Route, Switch, Link } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import Users from './components/Users';
import Products from './components/Products';
import { Box } from '@mui/system';
import NavBar from './components/NavBar';
import NavigationDrawer from './components/NavigationDrawer';
import Subscriptions from './components/Subscriptions';
import UserEdit from './components/edit/User';

import Stats from './components/stats/Stats';
import ProductEdit from './components/Product/ProductEdit';

export const history = createBrowserHistory();

function App() {
  const { isLoading, getAccessTokenSilently, isAuthenticated, logout } =
    useAuth0();
  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    async function getToken() {
      // get access_token
      console.log('access_token', );
      const access_token = isAuthenticated
        ? await getAccessTokenSilently()
        : null;

      // set interceptor to use access_token on every request

      //console.log('access_token', access_token);
      axios.interceptors.request.use(
        (config) => {
          config.headers.Authorization = 'Bearer ' + access_token;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      setAppReady(true); // flag app as ready
    }

    if (!isLoading) getToken();
  }, [appReady, isLoading, getAccessTokenSilently, isAuthenticated]);

  if (isLoading || !appReady) {
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  let params = new URLSearchParams(window.location.search);
  let error = params.get('error');
  if (!isAuthenticated && error === 'unauthorized') {
    logout({ returnTo: window.location.origin + '/unauthorized' });
    return <></>;
  }

  return (
    <div className='App'>
      <NavBar />
      <Router history={history}>
        <Switch>
          <Route path='/unauthorized'>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div style={{ margin: 20 }}>
                Este sitio es solo para Administradores de <b>Bukest</b>. Su
                usuario no tiene autorización para acceder.
              </div>
              <Link to='/'>Home</Link>
            </Box>
          </Route>
          <ProtectedRoute
            path='/'
            exact
            component={() => (
              <Box>
                <NavigationDrawer />
              </Box>
            )}
          />
          <ProtectedRoute path='/users' exact component={Users} />
          <ProtectedRoute path='/users/edit/:id' exact component={UserEdit} />
          <ProtectedRoute path='/products' exact component={Products} />
          <ProtectedRoute path='/stats' exact component={Stats} />
          <ProtectedRoute path='/products/edit/:id' exact component={ProductEdit} />
          <ProtectedRoute
            path='/card-personal'
            exact
            component={() => <Products isPersonalCard />}
          />
          <ProtectedRoute
            path='/providers'
            exact
            component={() => <Users providers />}
          />
          <ProtectedRoute
            path='/subscriptions'
            exact
            component={Subscriptions}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
