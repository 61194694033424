import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import AdminService from "../../services/AdminService";
import TextField from "@mui/material/TextField";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { useHistory, useParams } from "react-router-dom";
import AddressPreview from "../edit/AddressPreview";
import { goToPublication } from "../Products";
import ProductImagesInput from "./ProductImagesInput";
import { openInNewWindow } from "../../tools/openInNewWindow";
import Availability from "./Availability";
import moment from "moment";

const CURRENCIES_BY_PAYMENT_TYPE = {
  paypal: ["USD", "EUR", "BRL"],
  mercado_pago: ["ARS"],
  free: [],
};

const ProductEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const { register, handleSubmit, watch, setValue } = useForm();
  const parentCategoryId = watch("parentCategoryId");
  const paymentType = watch("paymentType");
  const userLocation = watch("userLocation");
  const distanceRange = watch("distanceRange");
  const inPerson = watch("inPerson");

  //inicializacion de los datos del usuario
  const name = watch("name");
  const lastName = watch("lastName");
  const phone = watch("phone");
  const avatar = watch("avatar");
  const country = watch("country");
  const profession = watch("profession");
  const description = watch("description");
  const email = watch("email");

  const [availability, setAvailability] = useState([]);
  const [originalAvailability, setOriginalAvailability] = useState([]);

  const [validationErrors, setValidationErrors] = useState([]);

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    if (!inPerson) {
      setValue("addressId", null);
      setValue("distanceRange", 0);
      setValue("userLocation", false);
      setValue("providerLocation", false);
    }
    // eslint-disable-next-line
  }, [inPerson]);

  useEffect(() => {
    if (paymentType === "free") {
      setValue("price", 0);
      setValue("currency", "");
    }
    // eslint-disable-next-line
  }, [paymentType]);

  useEffect(() => {
    if (product) {
      setValue("userLocation", product.userLocation);
      setValue("inPerson", product.inPerson);
      setValue("paymentType", product.paymentType);
      setValue("addressId", product.address?.id || null);
      setValue("duration", product.duration || 0);
      setValue("maxCapacity", product.maxCapacity || 0);
      AdminService.validateProduct(product.id)
        .then((resp) => {
          setValidationErrors(resp?.data || []);
        })
        .catch(console.log);
    }
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    if (product?.user) {
      setValue("name", product.user.name);
      setValue("lastName", product.user.lastName);
      setValue("phone", product.user.phone);
      setValue("avatar", product.user.avatar);
      setValue("country", product.user.country);
      setValue("profession", product.user.profession);
      setValue("description", product.user.description);
      setValue("email", product.user.email);
    }
  }, [product?.user]);

  const getProduct = async () => {
    try {
      const resp = await AdminService.getProduct(id);
      const prod = resp?.data;
      setProduct(prod);
      prod && getProviderAddresses(prod?.user?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const getProviderAddresses = async (userId) => {
    try {
      const resp = await AdminService.getUserAddresses(userId);
      setAddresses(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const resp = await AdminService.getCategories();
      setCategories(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSubCategories = async (parentId) => {
    try {
      const resp = await AdminService.getCategories(parentId);
      setSubCategories(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setProduct(null);
    try {
      data.id = parseInt(id);
      await AdminService.updateProduct(data);
      await setDaysOnCalendar(id);

      await getProduct();
      //history.push('/products');
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
    getCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      categories?.length > 0 &&
      (parentCategoryId || product?.category?.parent?.id)
    ) {
      getSubCategories(parentCategoryId || product?.category?.parent?.id);
    }
  }, [categories, product, parentCategoryId]);

  const styles = {
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 10,
      alignItems: "flex-start",
      padding: 5,
      width: "100%",
    },
    input: {
      width: "100%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  const openUserDetails = (userId) => {
    openInNewWindow(`/users/edit/${userId}`);
  };

  const calcAddedDates = () => {
    const availabs = availability.map((t) =>
      moment.utc(t.format().split("T")[0])
    );

    let nad = [];
    availabs.forEach((ad) => {
      if (
        originalAvailability.filter(
          (oad) => oad.format("DD-MM-YYYY") === ad.utc().format("DD-MM-YYYY")
        ).length === 0
      )
        nad.push(ad);
    });

    //remove duplicates
    nad = nad.filter((v, i) => {
      return nad.findIndex((candidate) => v.isSame(candidate)) === i;
    });
    return nad;
  };

  const calcRemovedDates = () => {
    const availabs = availability.map((t) =>
      moment.utc(t.format().split("T")[0])
    );

    let rad = [];
    originalAvailability.forEach((oad) => {
      if (
        availabs.filter(
          (ad) => ad.format("DD-MM-YYYY") === oad.format("DD-MM-YYYY")
        ).length === 0
      )
        rad.push(moment.utc(oad.format().split("T")[0]));
    });

    //remove duplicates
    rad = rad.filter((v, i) => {
      return rad.findIndex((candidate) => v.isSame(candidate)) === i;
    });
    return rad;
  };

  const setDaysOnCalendar = async (productId) => {
    let removedDates = calcRemovedDates();
    let addedDates = calcAddedDates();
    await AdminService.setAvailabilityonCalendar(
      addedDates?.length > 0
        ? addedDates.map((ad) => moment(ad).format("YYYY-MM-DD"))
        : null,
      removedDates?.length > 0 ? removedDates : null,
      productId
    );
  };

  console.log("product", product);

  if (!product) return <CircularProgress />;

  return (
    <Container>
      <h2 style={{ textAlign: "center" }}>
        Publicación{" "}
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => goToPublication(product.id)}
        >
          {" "}
          #{product.id}
        </Link>
      </h2>
      <h3>
        Prestador:{" "}
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => openUserDetails(product.user.id)}
        >
          {product.user.email}
        </Link>
      </h3>
      <h3 style={{ display: "flex" }}>
        <Box style={{ marginRight: 10 }}>Listo para Publicar?</Box>
        <Box
          style={{
            color: product.readyToPublish ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {product.readyToPublish ? "SI" : "NO"}
        </Box>
      </h3>
      {!product.readyToPublish && (
        <Box
          style={{
            padding: 5,
            margin: 5,
            borderRadius: 10,
            border: "2px solid red",
          }}
        >
          <Box style={{ fontWeight: "bold" }}>Datos faltantes:</Box>
          <List>
            {validationErrors.map((ve) => (
              <ListItem>{ve.description}</ListItem>
            ))}
          </List>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
        <div style={styles.inputContainer}>
          <InputLabel>Titulo</InputLabel>
          <TextField
            {...register("title")}
            style={styles.input}
            defaultValue={product.title}
          />
        </div>
        <div style={styles.inputContainer}>
          <FormControlLabel
            control={
              <Checkbox
                {...register("autoApproval")}
                defaultValue={product.autoApproval}
                defaultChecked={product.autoApproval}
              />
            }
            label="Auto-aprobar Reservas"
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Descripción</InputLabel>
          <TextField
            {...register("description")}
            multiline
            style={{ ...styles.input, whiteSpace: "pre-wrap" }}
            defaultValue={product.description}
          />
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Categoria</InputLabel>
          <Select
            {...register("parentCategoryId")}
            style={styles.input}
            defaultValue={product.category?.parent?.id}
          >
            {categories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div style={styles.inputContainer}>
          <InputLabel>Sub-categoría</InputLabel>
          <Select
            {...register("categoryId")}
            style={styles.input}
            defaultValue={product.category?.id}
          >
            {subCategories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div style={styles.inputContainer}>
          <InputLabel>Medio de Pago</InputLabel>
          <RadioGroup
            defaultValue={product.paymentType}
            name="paymentMethodRadioGroup"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value={"free"}
              control={<Radio {...register("paymentType")} />}
              label="Gratis"
            />
            <FormControlLabel
              value={"mercado_pago"}
              control={<Radio {...register("paymentType")} />}
              label="MercadoPago"
            />
            <FormControlLabel
              value={"paypal"}
              control={<Radio {...register("paymentType")} />}
              label="Paypal"
            />
          </RadioGroup>
        </div>

        {paymentType !== "free" && (
          <Box style={{ display: "flex", width: "100%" }}>
            <div style={styles.inputContainer}>
              <InputLabel>Precio</InputLabel>
              <TextField
                {...register("price")}
                type="number"
                style={styles.input}
                defaultValue={product.price}
              />
            </div>
            <div style={styles.inputContainer}>
              <InputLabel>Moneda</InputLabel>
              <Select
                {...register("currency")}
                style={styles.input}
                defaultValue={product.currency}
              >
                {CURRENCIES_BY_PAYMENT_TYPE[paymentType]?.map((c) => (
                  <MenuItem key={c + "_curr"} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Box>
        )}

        <div style={styles.inputContainer}>
          <InputLabel>Capacidad</InputLabel>
          <TextField
            {...register("maxCapacity")}
            type="number"
            style={styles.input}
            defaultValue={product.maxCapacity}
          />
        </div>

        <div style={styles.inputContainer}>
          <InputLabel>Duracion (en minutos)</InputLabel>
          <TextField
            {...register("duration")}
            type="number"
            style={styles.input}
            defaultValue={product.duration}
          />
        </div>

        <div style={styles.inputContainer}>
          <InputLabel>Tipo de Reunión</InputLabel>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  {...register("virtual")}
                  defaultValue={product.virtual}
                  defaultChecked={product.virtual}
                />
              }
              label="Virtual"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...register("inPerson")}
                  defaultValue={product.inPerson}
                  defaultChecked={product.inPerson}
                />
              }
              label="Presencial"
            />
          </Box>
        </div>

        {inPerson && (
          <div style={styles.inputContainer}>
            <InputLabel>Lugar de la Reunión</InputLabel>
            <Select
              {...register("addressId")}
              style={styles.input}
              defaultValue={product?.address?.id}
            >
              {addresses.map((a) => (
                <MenuItem key={a.id + "_add"} value={a.id}>
                  <AddressPreview address={a} onClick={() => null} />
                </MenuItem>
              ))}
            </Select>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("providerLocation")}
                    defaultValue={product.providerLocation}
                    defaultChecked={product.providerLocation}
                  />
                }
                label="Domicilio del Prestador"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("userLocation")}
                    defaultValue={userLocation ?? product.userLocation}
                    defaultChecked={userLocation ?? product.userLocation}
                  />
                }
                label="Domicilio del Usuario"
              />
              {userLocation && (
                <Box style={{ marginBottom: 20, padding: 10 }}>
                  Distancia maxima que se desplaza el prestador (en Km):{" "}
                  {distanceRange || product.distanceRange}
                  <Slider
                    defaultValue={product.distanceRange || 0}
                    min={0}
                    max={100}
                    {...register("distanceRange")}
                  />
                </Box>
              )}
            </Box>
          </div>
        )}

        <h2 style={{ marginTop: 10, fontWeight: "bold" }}>Fotos</h2>
        <div style={styles.inputContainer}>
          <ProductImagesInput
            isVideoCover={product?.isVideoCover}
            productImages={product?.images?.map((i) => i.imageUrl) || []}
            setValue={setValue}
          />
        </div>

        <h2 style={{ marginTop: 10, fontWeight: "bold" }}>Disponibilidad</h2>
        <div style={styles.inputContainer}>
          <Availability
            {...{
              product,
              availability,
              setAvailability,
              originalAvailability,
              setOriginalAvailability,
            }}
          />
        </div>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: 400,
            marginBottom: 40,
            marginTop: 20,
          }}
        >
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() => history.push("/products")}
          >
            Cancelar
          </Button>
          <Button variant="contained" type="submit" disabled={loading}>
            {loading ? <CircularProgress /> : "Guardar"}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default ProductEdit;
