import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  TablePagination,
  IconButton,
  Avatar,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import AdminService from "../../services/AdminService";
import NavigationDrawer from "../NavigationDrawer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import usePagination from "../../hooks/usePagination";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PublicationsModal from "./PublicationsModal";
import { openInNewWindow } from "../../tools/openInNewWindow";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ColaboratorsModal from "./ColaboratorsModal";

import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import ListIcon from "@mui/icons-material/List";

const Users = ({ providers }) => {
  const [selectedProvider, setselectedProvider] = useState(null);
  const [publicationsModalOpen, setPublicationsModalOpen] = useState(false);
  const [colaboratorsModalOpen, setColaboratorsModalOpen] = useState(false);

  const handleOpen = (provider) => {
    setselectedProvider(null);
    setPublicationsModalOpen(true);
    setselectedProvider(provider);
  };
  const handleOpenColaborators = (provider) => {
    setselectedProvider(null);
    setColaboratorsModalOpen(true);
    setselectedProvider(provider);
  };
  const handleClose = () => setPublicationsModalOpen(false);

  const [users, setUsers] = useState([]);
  const [countUsers, setCountUsers] = useState(0);
  const [loading, setLoading] = useState(false);
  const [freeText, setFreeText] = useState("");
  const [userProvider, setUserProvider] = useState([]);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(0, 100);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - countUsers) : 0;

  const fetchUsers = () => {
    setLoading(true);
    AdminService.getUsers({
      provider: providers,
      pageNumber: page + 1,
      pageSize: rowsPerPage,
      freeText,
    })
      .then((resp) => {
        setUsers(resp?.data?.data || []);
        setCountUsers(resp?.data?.metadata?.count || 0);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => setLoading(false));
  };

  // const getUsersAs = async () => {
  //   try {
  //     const resp = await AdminService.getUsers({
  //       provider: providers,
  //       pageNumber: page + 1,
  //       pageSize: rowsPerPage,
  //       freeText
  //     });
  //     setUsers(resp?.data?.data || []);
  //     setCountUsers(resp?.data?.metadata?.count || 0);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   getUsersAs();
  // }, []);

  const toAcceptStatus = async (id) => {
    try {
      await AdminService.updateUserState({ id, state: "accepted" });
      await fetchUsers();
    } catch (error) {
      console.log(error);
    }
  };

  
  const toSearchBarAccept = async (id) => {
    try {
      await AdminService.updateUser({ id, searchBar: true });
      await fetchUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const toSearchBarReject = async (id) => {
    try {
      await AdminService.updateUser({ id, searchBar: false });
      await fetchUsers();
    } catch (error) {
      console.log(error);
    }
  };



  const toRejectStatus = async (id) => {
    try {
      await AdminService.updateUserState({ id, state: "rejected" });
      await fetchUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const toEditProfile = (id) => {
    openInNewWindow(`/users/edit/${id}`);
  };

  const toColaborators = (id) => {
    openInNewWindow(`/colaborators/edit/${id}`);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, [page, rowsPerPage, freeText]);

  //console.log("users", users);

  const dateFormat = (date) => {
    const fecha = new Date(date);

    // Obtener componentes de la fecha
    const day = fecha.getDate();
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();
    const hour = fecha.getHours().toString().padStart(2, "0");
    const minute = fecha.getMinutes().toString().padStart(2, "0");
    //const second = fecha.getSeconds();

    const fechaLegible = `${day}/${month}/${year} ${hour}:${minute}`;

    return fechaLegible;
  };

  const fetchProvidersColab = async () => {
    AdminService.getProvidersStore("261")
      .then(({ data }) => {
        //console.log('data', data.providers);
        const filterProvidersStatus = data?.providers?.filter(
          (provider) =>
            provider.status !== "DELETED" && provider.status !== "OWNER"
        );
        setUserProvider(filterProvidersStatus);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
const isCompleted =(user) => {
  if (user?.email && user?.name && user?.lastName && user?.profession) {
    return true
  }else{
    return false
  }
}
  useEffect(() => {
    fetchProvidersColab();
  }, []);

  const styles = {
    chipVerified: {
      display: "flex",
      justifyContent: "center",
      color: "black",
      padding: 1,
      borderRadius: 5,
      boxShadow: 1,
      border: 1,
      borderColor: "green",
    },
    chipNotVerified: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      padding: 1,
      borderRadius: 5,
      boxShadow: 1,
      border: 1,
      borderColor: "red",
    },
  };





  return (
    <Box>
      <NavigationDrawer />
      <Box style={{ marginLeft: "195px", padding: 10 }}>
        <h1>{providers ? "Prestadores" : "Usuarios"}</h1>
        <Divider sx={{ backgroundColor: "#4B599E" }} />
        <Box>
          <TextField
            value={freeText}
            sx={{
              width: "100%",
              marginY: "20px",
            }}
            onChange={(e) => setFreeText(e.target.value)}
            placeholder="Buscar..."
          />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <PublicationsModal
                provider={selectedProvider}
                open={publicationsModalOpen}
                handleClose={handleClose}
              />
              <ColaboratorsModal
                open={colaboratorsModalOpen}
                user={selectedProvider}
                handleClose={() => setColaboratorsModalOpen(false)}
              />
              <TableContainer
                component={Paper}
                sx={{
                  //backgroundColor: "#F8F7F7",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  //border: "1px solid black",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#F8F7F7",
                        //border: "1px solid black",
                      }}
                    >
                      <TableCell>ID</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Fecha Creación</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">MercadoPago</TableCell>
                      <TableCell align="center">Paypal</TableCell>
                      <TableCell align="center">Calendar</TableCell>
                      <TableCell align="center">Buscador</TableCell>
                      {/*<TableCell>Google Calendar</TableCell>*/}
                      {/* <TableCell>Estado</TableCell> */}
                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {users.sort((a,b) => a.id - b.id) */}
                    {users.map((user) => (
                      <TableRow tabIndex={-1} key={user.id}>
                        <TableCell
                          component="th"
                          scope="user"
                          sx={{ fontSize: 16 }}
                        >
                          #{user.id}
                        </TableCell>
                        <TableCell
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: 15,
                            //fontWeight: 500,
                            color: user.name || user.lastName ? "black" : "red",
                          }}
                        >
                          <Avatar
                            src={user.avatar}
                            style={{ marginRight: 10 }}
                          />
                          {user.name || user.lastName
                            ? `${user.name} ${user.lastName}`
                            : "S/A"}
                        </TableCell>
                        <TableCell>{dateFormat(user?.createdDate)}</TableCell>
                        <TableCell align="center">{user.email}</TableCell>
                        <TableCell align="center">
                          {user.mercadoPagoCredential ? (
                            //<Box sx={styles.chipVerified}>
                            <CheckCircleOutlineIcon
                              style={{ color: "green" }}
                            />
                          ) : (
                            //</Box>
                            //<Box sx={styles.chipNotVerified}>
                            <HighlightOffIcon style={{ color: "red" }} />
                            //</Box>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {user.paypalCredential ? (
                            //<Box sx={styles.chipVerified}>
                            <CheckCircleOutlineIcon
                              style={{ color: "green" }}
                            />
                          ) : (
                            //</Box>
                            // <Box sx={styles.chipNotVerified}>
                            <HighlightOffIcon style={{ color: "red" }} />
                            //</Box>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {user.googleCalendarLinked ? (
                            //<Box sx={styles.chipVerified}>
                            <CheckCircleOutlineIcon
                              style={{ color: "green" }}
                            />
                          ) : (
                            //</Box>
                            //<Box sx={styles.chipNotVerified}>
                            <HighlightOffIcon style={{ color: "red" }} />
                            //</Box>
                          )}
                        </TableCell>

                        <TableCell align="center">
                          {user.searchBar ? (
                            //<Box sx={styles.chipVerified}>
                            <CheckCircleOutlineIcon onClick={() =>
                              toSearchBarReject(Number(user.id))
                            }
                              style={{ cursor:"pointer",color: "green" }}
                            />
                          ) : (
                            //</Box>
                            //<Box sx={styles.chipNotVerified}>
                            <HighlightOffIcon   onClick={() =>
                              toSearchBarAccept(Number(user.id))
                            } style={{ cursor:"pointer", color: "red" }} />
                            //</Box>
                          )}
                        </TableCell>
                        {/*<TableCell>
                          {user.googleCalendarLinked ? 'SI' : 'NO'}
                        </TableCell>*/}
                        {/* <TableCell>{user.state}</TableCell> */}
                        <TableCell>
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="space-evenly"
                          >
                            {/* {user.state !== "accepted" && (
                              <Tooltip title="Aceptar" arrow>
                                <IconButton
                                  aria-label="accept"
                                  style={{
                                    //backgroundColor: "green",
                                    padding: 7,
                                  }}
                                  onClick={() =>
                                    toAcceptStatus(Number(user.id))
                                  }
                                >
                                  <CheckIcon
                                    style={{ color: "green", fontSize: 22 }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {user.state !== "rejected" && (
                              <Tooltip title="Rechazar" arrow>
                                <IconButton
                                  aria-label="reject"
                                  style={{
                                    //backgroundColor: "red",
                                    padding: 7,
                                  }}
                                  onClick={() =>
                                    toRejectStatus(Number(user.id))
                                  }
                                >
                                  <DoDisturbAltIcon
                                    style={{ color: "#C50000", fontSize: 22 }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )} */}

                            <Tooltip title="Editar" arrow>
                              <IconButton
                                aria-label="edit"
                                style={{
                                  //backgroundColor: "gray",
                                  padding: 7,
                                }}
                                onClick={() => toEditProfile(Number(user.id))}
                              >
                                <EditIcon
                                  style={{ color: "#4B599E", fontSize: 22 }}
                                />
                              </IconButton>
                            </Tooltip>

                            {providers && (
                              <Tooltip title="Ver publicaciones" arrow>
                                <IconButton
                                  aria-label="edit"
                                  style={{
                                    //backgroundColor: "gray",
                                    padding: 7,
                                  }}
                                  onClick={() => handleOpen(user)}
                                >
                                  <FormatListBulletedIcon
                                    style={{ color: "#4B599E", fontSize: 22 }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}

                              <Tooltip title= {isCompleted(user)?"Perfil completo":"Perfil incompleto"} arrow>
                                <IconButton
                                  aria-label="accept"
                                  style={{
                                    padding: 7,
                                  }}
                                >
                                {isCompleted(user) ? (
                            <CheckCircleOutlineIcon 
                              style={{ cursor:"pointer",color: "green" }}
                            />
                          ) : (
                    
                            <HighlightOffIcon   style={{ cursor:"pointer", color: "red" }} />
                    
                          )}
                                </IconButton>
                              </Tooltip>

                            {/* <Tooltip title="Colaboradores" arrow>
                              <IconButton
                                aria-label="edit"
                                style={{
                                  //backgroundColor: "gray",
                                  padding: 7,
                                }}
                                onClick={() => {
                                  handleOpenColaborators(user);
                                }}
                              >
                                <GroupIcon
                                  style={{ color: "#4B599E", fontSize: 22 }}
                                />
                              </IconButton>
                            </Tooltip> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 69 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={countUsers}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default Users;
