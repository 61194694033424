import { useParams } from 'react-router-dom';
import RUG, { DropArea } from 'react-upload-gallery';
import 'react-upload-gallery/dist/style.css';
import { useState } from 'react';
import { Button, FormHelperText } from '@mui/material';
import AdminService from '../../services/AdminService';

const ProductImagesInput = ({ productImages, setValue, isVideoCover }) => {
  const { id } = useParams()
  const [imageWarning, setImageWarning] = useState(false);
  const [images, setImages] = useState(productImages || []);

  const customRequest = ({
    uid,
    file,
    action,
    onProgress,
    onSuccess,
    onError,
  }) => {
    const uploadProgressFn = ({ total, loaded, }) => {
      onProgress(uid, Math.round((loaded / total) * 100));
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      let { data } = await AdminService.loadPorductImage({
        file,
        productId: id || 0,
        onUploadProgress: uploadProgressFn,
      });

      setImages([data[0], ...images]);
      onSuccess(uid, { url: data[0] });
    };

    reader.onerror = (error) => {
      onError(uid, {
        action,
      });
    };

    return {
      abort() { },
    };
  };

  return (
    <RUG
      rules={{ limit: 9, size: 5000 }}
      onSuccess={hackFnToUpdateOrderAfterLoading}
      onSortEnd={(imgs) => {
        console.log('imgs', imgs)
        const prodImgs = imgs.map((img) => img.source);
        setValue('imageUrls', prodImgs);
      }}
      className={isVideoCover ? '' : 'imageCover'}
      style={{ width: '100%' }}
      initialState={images.map((img) => ({ source: img }))}
      source={(response) => response.url}
      sorting={{ axis: 'xy', pressDelay: 200, distance: 0 }}
      customRequest={customRequest}
      onDeleted={(image) => {
        console.log('toremove', image)
        console.log(images)
        const prodImgs = images.filter((i) => i !== image.source);
        console.log(prodImgs)

        setValue('imageUrls', prodImgs);
      }}
      header={({ openDialogue }) => (
        <>
          <DropArea>
            {(isDrag) => (
              <div
                style={{
                  border: 'dashed',
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                  borderRadius: '4px',
                  background: isDrag ? 'lightgrey' : '#fff',
                  marginBottom: 10,
                }}
              >
                <div style={{
                  margin: '5% 0 2%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                  <Button
                    type='button'
                    onClick={openDialogue}
                    style={{
                      borderRadius: '10px',
                      borderColor: ' #003c84',
                      backgroundColor: '#003c84',
                      height: '50px',
                      color: 'rgb(255, 255, 255)',
                      fontWeight: 'bold',
                      padding: '6px 16px'
                    }}
                  >
                    Subir imágenes
                  </Button>
                  <div
                    style={{
                      color: 'rgb(56, 56, 57)',
                      marginTop: '5px',
                      marginBottom: '20px',
                      fontSize: '14px',
                    }}
                  >
                    o arrástralas
                  </div>
                </div>
              </div>
            )}
          </DropArea>
          {imageWarning && (
            <FormHelperText error>{imageWarning}</FormHelperText>
          )}
        </>
      )}
      onWarning={(type, rules) => {
        switch (type) {
          case 'limit':
            setImageWarning(
              'Has cargado el máximo de imágenes: ' + rules.limit
            );
            break;
          case 'size':
            setImageWarning('El tamañno máximo de imagen es 5mb.');
            break;
          default:
        }
      }}
    />

  );
}

export default ProductImagesInput;

// not best solution, but works ;)
const hackFnToUpdateOrderAfterLoading = (image) => {
  const element = document
    .getElementsByClassName('rug-items __card __sorting')[0]
    .getElementsByTagName('div')[0];
  element.dispatchEvent(
    new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      screenX: 0,
      screenY: 0,
    })
  );
  setTimeout(
    () =>
      element.dispatchEvent(
        new MouseEvent('mouseup', {
          bubbles: true,
          cancelable: true,
          screenX: 0,
          screenY: 0,
        })
      ),
    201
  );
};
