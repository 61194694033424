export const labels = {
    "languages": {
        "es": "Español",
        "pt": "Portugués",
        "en": "Inglés",
        "ko": "Coreano",
        "zh": "Chino",
        "de": "Alemán",
        "it": "Italiano",
        "fr": "Francés",
        "ja": "Japonés",
        "ru": "Ruso"
    },
    "countries": {
        "BD": "Bangladesh",
        "BE": "Bélgica",
        "BF": "Burkina Faso",
        "BG": "Bulgaria",
        "BA": "Bosnia y Herzegovina",
        "BB": "Barbados",
        "WF": "Wallis y Futuna",
        "BL": "San Bartolomé",
        "BM": "Bermudas",
        "BN": "Brunei",
        "BO": "Bolivia",
        "BH": "Bahrein",
        "BI": "Burundi",
        "BJ": "Benin",
        "BT": "Bhután",
        "JM": "Jamaica",
        "BV": "Isla Bouvet",
        "BW": "Botswana",
        "WS": "Samoa",
        "BQ": "Bonaire, San Eustaquio y Saba",
        "BR": "Brasil",
        "BS": "Bahamas",
        "JE": "Jersey",
        "BY": "Belarús",
        "BZ": "Belice",
        "RU": "Rusia",
        "RW": "Ruanda",
        "RS": "Serbia",
        "TL": "Timor Oriental",
        "RE": "Reunión",
        "TM": "Turkmenistán",
        "TJ": "Tayikistán",
        "RO": "Rumania",
        "TK": "Tokelau",
        "GW": "Guinea-Bissau",
        "GU": "Guam",
        "GT": "Guatemala",
        "GS": "Georgia del Sur y las Islas Sandwich del Sur",
        "GR": "Grecia",
        "GQ": "Guinea Ecuatorial",
        "GP": "Guadalupe",
        "JP": "Japón",
        "GY": "Guyana",
        "GG": "Guernsey",
        "GF": "Guayana Francesa",
        "GE": "Georgia",
        "GD": "Granada",
        "GB": "Reino Unido",
        "GA": "Gabón",
        "SV": "El Salvador",
        "GN": "Guinea",
        "GM": "Gambia",
        "GL": "Groenlandia",
        "GI": "Gibraltar",
        "GH": "Ghana",
        "OM": "Omán",
        "TN": "Túnez",
        "JO": "Jordania",
        "HR": "Croacia",
        "HT": "Haití",
        "HU": "Hungría",
        "HK": "Hong Kong",
        "HN": "Honduras",
        "HM": "Isla Heard e Islas McDonald",
        "VE": "Venezuela",
        "PR": "Puerto Rico",
        "PS": "Territorio Palestino",
        "PW": "Palau",
        "PT": "Portugal",
        "SJ": "Svalbard y Jan Mayen",
        "PY": "Paraguay",
        "IQ": "Irak",
        "PA": "Panamá",
        "PF": "Polinesia Francesa",
        "PG": "Papua Nueva Guinea",
        "PE": "Perú",
        "PK": "Pakistán",
        "PH": "Filipinas",
        "PN": "Pitcairn",
        "PL": "Polonia",
        "PM": "San Pedro y Miquelón",
        "ZM": "Zambia",
        "EH": "Sáhara Occidental",
        "EE": "Estonia",
        "EG": "Egipto",
        "ZA": "Sudáfrica",
        "EC": "Ecuador",
        "IT": "Italia",
        "VN": "Vietnam",
        "SB": "Islas Salomón",
        "ET": "Etiopía",
        "SO": "Somalia",
        "ZW": "Zimbabwe",
        "SA": "Arabia Saudita",
        "ES": "España",
        "ER": "Eritrea",
        "ME": "Montenegro",
        "MD": "Moldova",
        "MG": "Madagascar",
        "MF": "San Martín",
        "MA": "Marruecos",
        "MC": "Mónaco",
        "UZ": "Uzbekistán",
        "MM": "Myanmar",
        "ML": "Malí",
        "MO": "Macao",
        "MN": "Mongolia",
        "MH": "Islas Marshall",
        "MK": "Macedonia",
        "MU": "Mauricio",
        "MT": "Malta",
        "MW": "Malawi",
        "MV": "Maldivas",
        "MQ": "Martinica",
        "MP": "Islas Marianas del Norte",
        "MS": "Montserrat",
        "MR": "Mauritania",
        "IM": "Isla de Man",
        "UG": "Uganda",
        "TZ": "Tanzania",
        "MY": "Malasia",
        "MX": "México",
        "IL": "Israel",
        "FR": "Francia",
        "IO": "Territorio Británico del Océano Índico",
        "SH": "Santa Elena",
        "FI": "Finlandia",
        "FJ": "Fiji",
        "FK": "Islas Malvinas",
        "FM": "Micronesia",
        "FO": "Islas Feroe",
        "NI": "Nicaragua",
        "NL": "Países Bajos",
        "NO": "Noruega",
        "NA": "Namibia",
        "VU": "Vanuatu",
        "NC": "Nueva Caledonia",
        "NE": "Níger",
        "NF": "Isla de Norfolk",
        "NG": "Nigeria",
        "NZ": "Nueva Zelanda",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "CK": "Islas Cook",
        "XK": "Kosovo",
        "CI": "Costa de Marfil",
        "CH": "Suiza",
        "CO": "Colombia",
        "CN": "China",
        "CM": "Camerún",
        "CL": "Chile",
        "CC": "Islas Cocos",
        "CA": "Canadá",
        "CG": "República del Congo",
        "CF": "República Centroafricana",
        "CD": "República Democrática del Congo",
        "CZ": "República Checa",
        "CY": "Chipre",
        "CX": "Isla de Navidad",
        "CR": "Costa Rica",
        "CW": "Curaçao",
        "CV": "Cabo Verde",
        "CU": "Cuba",
        "SZ": "Swazilandia",
        "SY": "Siria",
        "SX": "San Martín",
        "KG": "Kirguistán",
        "KE": "Kenya",
        "SS": "Sudán del Sur",
        "SR": "Suriname",
        "KI": "Kiribati",
        "KH": "Camboya",
        "KN": "San Cristóbal y Nieves",
        "KM": "Comoras",
        "ST": "Santo Tomé y Príncipe",
        "SK": "Eslovaquia",
        "KR": "Corea del Sur",
        "SI": "Eslovenia",
        "KP": "Corea del Norte",
        "KW": "Kuwait",
        "SN": "Senegal",
        "SM": "San Marino",
        "SL": "Sierra Leona",
        "SC": "Seychelles",
        "KZ": "Kazajstán",
        "KY": "Islas Caimán",
        "SG": "Singapur",
        "SE": "Suecia",
        "SD": "Sudán",
        "DO": "República Dominicana",
        "DM": "Dominica",
        "DJ": "Djibouti",
        "DK": "Dinamarca",
        "VG": "Islas Vírgenes Británicas",
        "DE": "Alemania",
        "YE": "Yemen",
        "DZ": "Argelia",
        "US": "Estados Unidos",
        "UY": "Uruguay",
        "YT": "Mayotte",
        "UM": "Islas menores alejadas de los Estados Unidos",
        "LB": "Líbano",
        "LC": "Santa Lucía",
        "LA": "Laos",
        "TV": "Tuvalu",
        "TW": "Taiwán",
        "TT": "Trinidad y Tabago",
        "TR": "Turquía",
        "LK": "Sri Lanka",
        "LI": "Liechtenstein",
        "LV": "Letonia",
        "TO": "Tonga",
        "LT": "Lituania",
        "LU": "Luxemburgo",
        "LR": "Liberia",
        "LS": "Lesotho",
        "TH": "Tailandia",
        "TF": "Territorios Australes Franceses",
        "TG": "Togo",
        "TD": "Chad",
        "TC": "Islas Turcas y Caicos",
        "LY": "Libia",
        "VA": "Vaticano",
        "VC": "San Vicente y las Granadinas",
        "AE": "Emiratos Árabes Unidos",
        "AD": "Andorra",
        "AG": "Antigua y Barbuda",
        "AF": "Afganistán",
        "AI": "Anguila",
        "VI": "Islas Vírgenes de los Estados Unidos",
        "IS": "Islandia",
        "IR": "Irán",
        "AM": "Armenia",
        "AL": "Albania",
        "AO": "Angola",
        "AQ": "Antártida",
        "AS": "Samoa Americana",
        "AR": "Argentina",
        "AU": "Australia",
        "AT": "Austria",
        "AW": "Aruba",
        "IN": "India",
        "AX": "Islas Aland",
        "AZ": "Azerbaiyán",
        "IE": "Irlanda",
        "ID": "Indonesia",
        "UA": "Ucrania",
        "QA": "Qatar",
        "MZ": "Mozambique"
    }
}