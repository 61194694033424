import axios from "axios";

let cancelTokenGetUsers;
let cancelTokenGetProducts;

const AdminService = {
  getUsers: (
    { provider, pageSize = 1000, pageNumber = 1, freeText } = {
      pageSize: 1000,
      pageNumber: 1,
    }
  ) => {
    console.log(`AdminService.getUsers(${pageSize}, ${pageNumber})`);

    if (typeof cancelTokenGetUsers !== typeof undefined)
      cancelTokenGetUsers.cancel(
        "getRooms Operation canceled due to new request."
      );

    cancelTokenGetUsers = axios.CancelToken.source();
    let url = `${process.env.REACT_APP_API_URL}/admin/users?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    if (provider) url += `&provider=${provider}`;
    if (freeText) url += `&freeText=${freeText}`;
    return axios.get(url, { cancelToken: cancelTokenGetUsers.token });
  },

  getUser: (id) => {
    console.log(`AdminService.getUser(${id})`);
    let url = `${process.env.REACT_APP_API_URL}/admin/user/${id}`;
    return axios.get(url);
  },

  getStats: async (dateFrom, dateTo) => {
    console.log(`AdminService.getStats(${dateFrom}, ${dateTo})`);
    const url = `${process.env.REACT_APP_API_URL}/admin/stats?dateFrom=${dateFrom}&dateTo=${dateTo}`;
    return axios.get(url);
  },

  getUserAddresses: (id) => {
    console.log(`AdminService.getUserAddresses(${id})`);
    let url = `${process.env.REACT_APP_API_URL}/admin/user/${id}/address`;
    return axios.get(url);
  },

  deleteUserAddress: (userID, addressID) => {
    console.log(`AdminService.deleteUserAddress(${userID}, ${addressID})`);
    let url = `${process.env.REACT_APP_API_URL}/admin/user/${userID}/address/${addressID}`;
    return axios.delete(url);
  },

  updateUser: (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/user/${payload.id}`;
    return axios.patch(url, payload);
  },
  updateUserState: (payload) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/user`;
    return axios.patch(url, payload);
  },

  getProducts: (
    { pageSize = 1000, pageNumber = 1, providerId,isPersonalCard = false, freeText } = {
      pageSize: 1000,
      pageNumber: 1,
    }
  ) => {
    console.log(`AdminService.getProducts(${pageSize}, ${pageNumber})`);

    if (typeof cancelTokenGetProducts !== typeof undefined)
      cancelTokenGetProducts.cancel(
        "getRooms Operation canceled due to new request."
      );

    cancelTokenGetProducts = axios.CancelToken.source();
    let url = `${process.env.REACT_APP_API_URL}/admin/products?isPersonalCard=${isPersonalCard}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
    if (providerId) url += `&providerId=${providerId}`;
    if (freeText) url += `&freeText=${freeText}`;
    return axios.get(url, { cancelToken: cancelTokenGetProducts.token });
  },

  updateProduct: (req) => {
    console.log(`AdminService.updateProduct(${req})`);
    const url = `${process.env.REACT_APP_API_URL}/admin/product/${req.id}`;
    return axios.patch(url, req);
  },

  loadPorductImage: ({ onUploadProgress, file, productId }) => {
    console.log(`AdminService.loadPorductImage(${productId})`);
    const formData = new FormData();
    formData.append("files", file);
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/product/${productId}/image`,
      formData,
      {
        headers: { "Content-type": "multipart/form-data" },
        onUploadProgress,
      }
    );
  },

  getProduct: (id) => {
    console.log(`AdminService.getProduct(${id})`);
    const url = `${process.env.REACT_APP_API_URL}/product/${id}`;
    return axios.get(url);
  },

  getSubscriptions: (
    { pageSize = 1000, pageNumber = 1 } = { pageSize: 1000, pageNumber: 1 }
  ) => {
    console.log(`AdminService.getSubscriptions(${pageSize}, ${pageNumber})`);
    const url = `${process.env.REACT_APP_API_URL}/admin/subscriptions?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axios.get(url);
  },

  getLanguages: () => {
    const url = `${process.env.REACT_APP_API_URL}/language`;
    return axios.get(url);
  },

  getCountries: () => {
    const url = `${process.env.REACT_APP_API_URL}/country`;
    return axios.get(url);
  },

  getCategories: (parentId) => {
    let url = `${process.env.REACT_APP_API_URL}/category`;
    if (parentId) url += "?parentId=" + parentId;
    return axios.get(url);
  },

  updateAvatar: (userId, file) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/user/${userId}/avatar`;
    const formData = new FormData();
    formData.append("file", file);
    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  bulkCreateWorkingHours: (productId, workingHours) => {
    console.log(`AdminService.bulkCreateWorkingHours(${productId})`);
    const url = `${process.env.REACT_APP_API_URL}/admin/product/${productId}/working-hour/bulk`;
    return axios.post(url, workingHours);
  },

  getAvailabilities: (productId, dateFrom, dateTo) => {
    console.log(
      `AdminService.getAvailabilities(${productId}, ${dateFrom}, ${dateTo})`
    );
    let url = `${process.env.REACT_APP_API_URL}/admin/product/${productId}/availability?productId=${productId}`;
    if (dateFrom) url += `&from=${dateFrom}`;
    if (dateTo) url += `&to=${dateTo}`;
    return axios.get(url);
  },

  setAvailabilityonCalendar: (addedDates, removedDates, productId) => {
    console.log(`AdminService.setAvailabilityonCalendar(${productId})`);
    const url = `${process.env.REACT_APP_API_URL}/admin/product/${productId}/availability`;
    return axios.patch(url, {
      added: addedDates,
      removed: removedDates,
      productId: productId,
    });
  },

  createWorkingHours: (productId, workingHour) => {
    console.log(`AdminService.createWorkingHours(${productId})`);
    const url = `${process.env.REACT_APP_API_URL}/admin/product/${productId}/working-hour`;
    return axios.post(url, workingHour);
  },

  removeWorkingHour: (productId, workingHourId) => {
    console.log(
      `AdminService.removeWorkingHour(${productId}, ${workingHourId})`
    );
    const url = `${process.env.REACT_APP_API_URL}/admin/product/${productId}/working-hour/${workingHourId}`;
    return axios.delete(url);
  },

  getWorkingHour: (productId) => {
    console.log(`AdminService.getWorkingHour(${productId})`);
    const url = `${process.env.REACT_APP_API_URL}/admin/product/${productId}/working-hour`;
    return axios.get(url);
  },

  validateProduct: (productId) => {
    console.log(`AdminService.validateProduct(${productId})`);
    const url = `${process.env.REACT_APP_API_URL}/admin/product/${productId}/validate`;
    return axios.get(url);
  },

  getProvidersStore: (storeId) => {
    const url = `${process.env.REACT_APP_API_URL}/store/${storeId}`;
    return axios.get(url);
  },

  updateProviders: async (id, data) => {
    const url = `${process.env.REACT_APP_API_URL}/provider/${id}`;
    return await axios.patch(url, data);
  },
  
  deleteProvider: async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/provider/${id}`;
    return await axios.delete(url);
  },

  getProviderxUser: (userID) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/provider/${userID}/validate`;
    return axios.get(url);
  },

  getStorexUser: (userID) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/user/${userID}/validate`;
    return axios.get(url);
  },

  getStore: (code) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/store/?code=${code}`;
    return axios.get(url);
  },

  patchColaborator: (providerID, state) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/provider/${providerID}/`;
    return axios.patch(url, {
      state: state,
    });
  },

  postAddress: (userID, data) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/user/${userID}/address`;
    return axios.post(url, data);
  }
};

export default AdminService;
