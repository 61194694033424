import { Box, CircularProgress, IconButton, Stack } from "@mui/material";
import * as React from "react";
import { openInNewWindow } from "../../tools/openInNewWindow";
import AdminService from "../../services/AdminService";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import AddressEditModal from "./ModalEditAddress";
export default function AddressPreview({
  address = {},
  onClick,
  idUser,
  getAddresses,
}) {
  const {
    alias,
    city,
    country,
    floor,
    latitude,
    longitude,
    street,
    streetNumber,
    zipCode,
  } = address;

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onAddressClick = () => {
    if (onClick) onClick();
    else if (latitude && longitude) {
      openInNewWindow(
        `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
      );
    }
  };

  const handleDeleteUserAddress = async () => {
    setLoading(true);
    try {
      const resp = await AdminService.deleteUserAddress(idUser, address.id);
      resp && getAddresses();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleEditUserAddress = () => {
    setOpen(true);
  };

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Box
        //onClick={onAddressClick}
        style={{
          border: "1px solid grey",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.30)",
          padding: 10,
          borderRadius: 10,
          width: "45%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            float: "right",
            cursor: "pointer",
          }}
        >
          <IconButton onClick={handleDeleteUserAddress}>
            <ClearIcon style={{ color: "#D73434", float: "right" }} />
          </IconButton>
          <IconButton onClick={onAddressClick}>
            <MapIcon style={{ color: "#4B599E", float: "right" }} />
          </IconButton>
          {/* <IconButton onClick={handleEditUserAddress}>
            <EditIcon style={{ color: "#4B599E", float: "right" }} />
          </IconButton> */}
        </Stack>
        <Box style={{ fontWeight: "bold" }}>{alias}</Box>
        <Box>
          {street} {streetNumber}, {city}, {country?.name}, {zipCode}
        </Box>
        <Box>{floor}</Box>
      </Box>
      <AddressEditModal
        open={open}
        handleClose={() => setOpen(false)}
        address={address}
        getAddresses={getAddresses}
      />
    </>
  );
}
