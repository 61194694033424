import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import { Box } from '@mui/system';

const ImageUpload = ({ setImage, avatar, image }) => {
  function getImageFileObject(imageFile = null) {
    if (imageFile.dataUrl) setImage(imageFile.file);
  }
  function runAfterImageDelete(file) {
    setImage(null);
  }
  return (
    <Box style={{ border: '1px solid grey', borderRadius: 10 }}>
      <ImageUploader
        onFileAdded={(img) => getImageFileObject(img)}
        onFileRemoved={(img) => runAfterImageDelete(img)}
        style={{ height: 200, width: 200, background: 'transparent' }}
        deleteIcon={
          (avatar && !image) ? <div /> :
            <img
              src='https://img.icons8.com/ios-glyphs/30/000000/delete-sign.png'
              alt=''
            />
        }
        uploadIcon={avatar ? <img
          style={{ width: 250 }}
          src={avatar}
          alt={""}
        />
          :
          <img src='https://img.icons8.com/ios-glyphs/30/000000/upload.png' alt='' />
        }
      />
    </Box>
  )
}

export default ImageUpload