import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Modal,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";

const AddressEditModal = ({ open, handleClose, address }) => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = React.useState(false);
  const style = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90vw",
      maxHeight: "80vh",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      overflow: "scroll",
      p: 4,
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 10,
      alignItems: "flex-start",
      padding: 5,
      width: "100%",
    },
    input: {
      width: "100%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style.modal}>
        <form onSubmit={handleSubmit()} style={style.form}>
          <h3>
            <div>Direccion</div>
          </h3>
          <div style={style.inputContainer}>
            <InputLabel>Nombre</InputLabel>
            <TextField
              //{...register("name")}
              style={style.input}
              defaultValue={address.alias}
            />
            <TextField
              //{...register("name")}
              style={style.input}
              defaultValue={address.alias}
            />
          </div>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: 400,
              marginBottom: 40,
              marginTop: 20,
            }}
          >
            <Button
              variant="outlined"
              disabled={loading}
              //onClick={() => history.push("/users")}
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit" disabled={loading}>
              {loading ? <CircularProgress /> : "Guardar"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
export default AddressEditModal;
