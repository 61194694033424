import React from 'react';
import { Box, Modal } from '@mui/material';
import Colaborators from './Colaborators';


const ColaboratorsModal = ({ open, handleClose, user }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        maxHeight: '80vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        overflow: 'scroll',
        p: 4,
    };

    return (
        <Modal open={open} onClose={handleClose} >
            <Box sx={style}>
                <Colaborators hideMenu user={user}/>
            </Box>
        </Modal>
    );
}
export default ColaboratorsModal;

