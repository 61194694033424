import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Link,
  Paper,
  TablePagination,
} from '@mui/material';

import * as XLSX from 'xlsx';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import NavigationDrawer from "../NavigationDrawer";
import HailIcon from '@mui/icons-material/Hail';
import SchoolIcon from '@mui/icons-material/School';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

import FactCheckIcon from '@mui/icons-material/FactCheck';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';


import ListAltIcon from '@mui/icons-material/ListAlt';


const Stats = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const formatPrice = (price) => {
    // Formatear el precio con punto como separador de miles y sin decimales
    return new Intl.NumberFormat('es-ES', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
      useGrouping: true, // Habilitar separadores de grupo (punto como separador de miles)
    }).format(price);
};
  const fetchStats = async () => {
    try {
      setLoading(true);
      const response = await AdminService.getStats(fromDate, toDate);
      setStats(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching stats:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);
  
  const handleFetchStats = () => {
    fetchStats();
  };

  const exportToExcel = () => {
    if (stats) {
      const wb = XLSX.utils.book_new();
  
      // Formatear las fechas del periodo
      const formattedFromDate = formatDate(new Date(fromDate));
      const formattedToDate = formatDate(new Date(toDate));
  
      // Formatear los datos para la hoja "Other Stats"
      const otherStatsData = [
        ["Nuevos usuarios", stats.newUsers],
        ["Nuevos clientes", stats.newClients],
        ["Nuevos prestadores", stats.newProviders],
        ["Clientes activos", stats.numActiveClients],
        ["Prestadores activos", stats.numActiveProviders],
        ["Transacciones del periodo", stats.newTransactions],
        ["Suscripciones canceladas", stats.cancelledSubscriptions],
        ["Suscripciones reprogramadas", stats.rescheduledSubscriptions],
        // Agrega otros datos según sea necesario
        []
      ];
  
      // Agregar los ingresos por moneda a la hoja "Other Stats"
      const incomeByCurrencyData = Object.entries(stats.incomeByCurrency).map(([currency, amount]) => [currency, amount]);
      const allCurrencies = ["ARS", "USD", "EUR"];
  
      allCurrencies.forEach(currency => {
        const currencyData = incomeByCurrencyData.find(([c]) => c === currency);
        if (!currencyData) {
          otherStatsData.push([currency, 0]);
        } else {
          otherStatsData.push(currencyData);
        }
      });
  
      // Convertir los datos de "Other Stats" en una hoja de Excel
      const otherStatsWs = XLSX.utils.aoa_to_sheet(otherStatsData);
  
      // Agregar la hoja "Other Stats" al libro
      XLSX.utils.book_append_sheet(wb, otherStatsWs, "Other Stats");
  
      // Formatear los datos para la hoja "Best Clients"
      const bestClientsData = [
        ["Nombre", "Correo electrónico", "Cantidad de compras", "Moneda", "Total gastado"],
        ...stats.bestClients?.map(client => [client.username, client.useremail, client.purchasecount, client.currency, client.totalspent]),
        []
      ];
  
      // Convertir los datos de "Best Clients" en una hoja de Excel
      const bestClientsWs = XLSX.utils.aoa_to_sheet(bestClientsData);
  
      // Agregar la hoja "Best Clients" al libro
      XLSX.utils.book_append_sheet(wb, bestClientsWs, "Best Clients");
  
      // Formatear los datos para la hoja "Best Providers"
      const bestProvidersData = [
        ["Nombre", "Correo electrónico", "Cantidad de transacciones", "Moneda", "Ingreso total"],
        ...stats.bestProviders?.map(provider => [provider.providername, provider.provideremail, provider.transactioncount, provider.currency, provider.totalincome]),
        []
      ];
  
      // Convertir los datos de "Best Providers" en una hoja de Excel
      const bestProvidersWs = XLSX.utils.aoa_to_sheet(bestProvidersData);
  
      // Agregar la hoja "Best Providers" al libro
      XLSX.utils.book_append_sheet(wb, bestProvidersWs, "Best Providers");
  
      // Nombrar el archivo con el formato "stats fechaDesde al fechaHasta.xlsx"
      const fileName = `stats_${formattedFromDate}_al_${formattedToDate}.xlsx`;
  
      // Descargar el archivo Excel
      XLSX.writeFile(wb, fileName);
    }
  };
  
  // Función para formatear una fecha en formato "dd-mm-yyyy"
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  

//console.log(stats)

  return (
    <Box>
      <NavigationDrawer />
      <Box marginLeft="195px" paddingLeft={"25px"} >
        <h1>Estadísticas</h1>
        <Box>
          {loading ? (
            <Box
              style={{
                width: "100%",
                height: "350px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box style={{width:"500px",flexDirection:"column", display:"flex", gap:"25px", alignItems:"center", marginBottom:"25px"}}>
                <Box style={{width:"500px", display:"flex", justifyContent:"space-evenly"}               }>
                <Box style={{display:"flex", flexDirection:"column", gap:"5px"}}>
                    <label htmlFor="fromDate">Desde:</label>
                    <input
                    style={{width:"150px"}}
                      type="date"
                      id="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </Box>
                  <Box style={{display:"flex", flexDirection:"column", gap:"5px"}}>
                    <label htmlFor="toDate">Hasta:</label>
                    <input
                    style={{width:"150px"}}
                      type="date"
                      id="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </Box>
                </Box>
                  

                <Button style={{width:"100%"}} variant="contained" onClick={handleFetchStats}>
                  Consultar
                </Button>

              </Box>

              <Box>
              <Box style={{display:"flex", flexDirection:"column", gap:"5px", padding:"15px"}}>

              <Typography style={{ color: "#5c5c5c", fontSize: "18px" }}>USUARIOS</Typography>

            <section style={{ width: "500px", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "5px",marginBottom:"25px" }}>
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Usuarios anteriores</Typography>
                    <PeopleAltIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.prevUsers}</Typography>
                </Box>

                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Nuevos prestadores</Typography>
                    <HailIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.newProviders}</Typography>
                </Box>

                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Prestadores activos</Typography>
                    <ToggleOnIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.numActiveProviders}</Typography>
                </Box>

                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Nuevos usuarios</Typography>
                    <PersonAddAltIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.newUsers}</Typography>
                </Box>

                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Nuevos clientes</Typography>
                    <SchoolIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.newClients}</Typography>
                </Box>

                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                  <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Clientes activos</Typography>
                    <ToggleOnIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.numActiveClients}</Typography>
                </Box>
              </section>

              <Typography style={{ color: "#5c5c5c", fontSize: "18px" }}>TRANSACCIONES</Typography>

              <section style={{ width: "500px", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "5px" ,marginBottom:"25px"}}>
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Transacciones previas</Typography>
                    <ListAltIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.prevTransactions}</Typography>
                </Box>

                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Transacciones en el periodo</Typography>
                    <ReceiptLongIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.newTransactions}</Typography>
                </Box>
             
          
               
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Cancelaciones</Typography>
                    <CancelIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.cancelledSubscriptions}</Typography>
                </Box>

                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Transacciones actuales</Typography>
                    <FactCheckIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.currentTransactions}</Typography>
                </Box>
            
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Incremento</Typography>
                    <TrendingUpIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{Math.floor(stats?.incrementPercentage)}%</Typography>
                </Box>
            
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                <Box style={{ width:"95%",display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>Reprogramaciones</Typography>
                    <ScheduleSendIcon style={{ color: "#5c5c5c" }} />
                  </Box>
                  <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{stats?.rescheduledSubscriptions}</Typography>
                </Box>
              </section>
  



              <Typography style={{ color: "#5c5c5c", fontSize: "18px" }}>MOVIMIENTOS POR MONEDA</Typography>

                <section style={{ width: "500px", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "5px" , marginBottom:"25px"}}>

                
                  <>
                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>          
                      <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>ARS</Typography>
                      <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}> {formatPrice(stats?.incomeByCurrency?.ARS || 0)}</Typography>
                    </Box>

                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                      <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>USD</Typography>
                      <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}>{formatPrice(stats?.incomeByCurrency?.USD || 0)}</Typography>
                    </Box>

                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "150px", height: "80px", border: "0.5px solid #5c5c5c" }}>
                      <Typography style={{ color: "#5c5c5c", fontSize: "12px" }}>EUR</Typography>
                      <Typography style={{ color: "#5c5c5c", fontSize: "24px" }}> {formatPrice(stats?.incomeByCurrency?.EUR || 0)}</Typography>
                    </Box>
                  </>                              
                           
              </section>
             

        




                <br></br>
                <Box style={{display:"flex", gap:"75px"}}>
                {stats?.bestClients && (
                  <Box  >
                    <Typography style={{ color: "#5c5c5c", fontSize: "18px" }} >Clientes con más transacciones:</Typography>
                    <Box  style={{ maxHeight: "500px", gap: "5px", overflowY:"scroll" }}>
                    <ul>
                      {stats.bestClients.map((client, index) => (
                        <li key={index}>
                          <Typography style={{ color: "#5c5c5c", fontSize: "14px" }} >
                            Cliente: <strong>{client.username}</strong>
                          </Typography>
                          <Typography style={{ color: "#5c5c5c", fontSize: "14px" }} >
                            Cantidad de transacciones: <strong>{client.purchasecount}</strong>
                          </Typography>
                          {/* <Typography variant="h5">Email: {client.useremail}</Typography> */}
                          {
                            (client?.currency !== "" && client.totalspent > 0) ? (
                              <>
                              <Typography style={{ color: "#5c5c5c", fontSize: "14px" }}>Moneda:<strong> {client.currency}</strong></Typography>
                              <Typography style={{ color: "#5c5c5c", fontSize: "14px" }}>Total gastado:<strong> {formatPrice(client.totalspent)}</strong></Typography>
                              </>
                            ):(
                              <Typography style={{ color: "#5c5c5c", fontSize: "14px" }}><strong>Clases Gratuitas</strong></Typography>
                            )
                          }
                          <hr />
                        </li>
                      ))}
                    </ul>
                    </Box>
                  </Box>
                )}

{stats?.bestProviders && (
                  <Box  >
                    <Typography style={{ color: "#5c5c5c", fontSize: "18px" }}>Prestadores con más transacciones:</Typography>
                    <Box  style={{ maxHeight: "500px", gap: "5px", overflowY:"scroll" }}>
                    <ul>
                      {stats.bestProviders.map((provider, index) => (
                        <li key={index}>
                          <Typography style={{ color: "#5c5c5c", fontSize: "14px" }} >Prestador: <strong>{provider.providername}</strong></Typography>
                          <Typography  style={{ color: "#5c5c5c", fontSize: "14px" }}>Cantidad de transacciones:<strong> {provider.transactioncount}</strong></Typography>
                          {/* <Typography variant="h5">Email: {client.useremail}</Typography> */}
                          {
                            provider?.currency !== "" ? (
                              <>
                              <Typography style={{ color: "#5c5c5c", fontSize: "14px" }}>Moneda: <strong>{provider.currency}</strong></Typography>
                              <Typography style={{ color: "#5c5c5c", fontSize: "14px" }}>Total facturado: <strong>{formatPrice(provider.totalincome)}</strong> </Typography>
                              </>
                            ):(
                              <Typography style={{ color: "#5c5c5c", fontSize: "14px" }}><strong>Clases Gratuitas</strong></Typography>
                            )
                          }
                          
                          <hr />
                        </li>
                      ))}
                    </ul>
                    </Box>
                  </Box>
                )}
                </Box>
 
            </Box>
              <Button style={{marginTop:"50px",marginBottom:"75px",width:"500px", backgroundColor:"#065c20"}} variant="contained" onClick={exportToExcel}>Exportar a Excel</Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default Stats;
