import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar-multiday';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AdminService from '../../services/AdminService';
import { Box, Button, InputLabel, MenuItem, Select } from '@mui/material';
import { useMediaQuery } from "react-responsive";

const EARLIER_CHECKIN_ALLOWED = '00:00'; // h:mm
const LATER_CHECKOUT_ALLOWED = '23:30'; // h:mm

const normalizeTime = (t) =>
    t?.toLocaleString('en-US', { minimumIntegerDigits: 2 }) || '';

const completeTimesOptions = Array(24)
    .fill(null)
    .map((_, i) => i)
    .map((h) => [`${normalizeTime(h)}:00`, `${normalizeTime(h)}:30`])
    .flat();

const allowedTimeOptions = completeTimesOptions.slice(
    completeTimesOptions.indexOf(EARLIER_CHECKIN_ALLOWED),
    completeTimesOptions.indexOf(LATER_CHECKOUT_ALLOWED) + 2
);

const daysList = [
    {
        id: 1,
        day: 'Lunes',
    },
    {
        id: 2,
        day: 'Martes',
    },
    {
        id: 3,
        day: 'Miercoles',
    },
    {
        id: 4,
        day: 'Jueves',
    },
    {
        id: 5,
        day: 'Viernes',
    },
    {
        id: 6,
        day: 'Sabado',
    },
    {
        id: 7,
        day: 'Domingo',
    },
];

function Availability({ product, availability, setAvailability, originalAvailability, setOriginalAvailability, edition = true, errors = {} }) {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [showAddNewTime, setShowAddNewTime] = useState(false);

    const [checkin, setCheckin] = useState('');
    const [checkout, setCheckout] = useState('');

    const [checkinOptions, setCheckinOptions] = useState(allowedTimeOptions);
    const [checkoutOptions, setCheckoutOptions] = useState(allowedTimeOptions);

    const [workingHours, setWorkingHours] = useState([]);
    const [dayOfWeek, setDayOfWeek] = useState('');

    const getAvailabilitiesReq = async () => {
        if (!edition) return;

        let dateFrom = moment().format('YYYY-MM-DD');
        let dateTo = moment()
            .add(1, 'years')
            .format('YYYY-MM-DD');

        await AdminService.getAvailabilities(product?.id, dateFrom, dateTo).then(
            (resp) => {
                let spaceAvailabilities = resp.data
                    .map((a) => moment(a.date))
                    .filter((a) => {
                        const isTodayOrAfter =
                            a.isAfter(moment()) ||
                            a.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY');
                        return isTodayOrAfter;
                    });

                setAvailability(spaceAvailabilities);
                setOriginalAvailability(spaceAvailabilities);
            }
        );
    };

    const setAvailabilityData = (e) => {
        setAvailability(
            e.selected.map((sd) =>
                momentTimezone.tz(sd, momentTimezone.tz.guess())
            )
        );
    };



    

    useEffect(() => {
        getAvailabilitiesReq();
        // eslint-disable-next-line
    }, []);

    /* hourSelector */



    useEffect(() => {
        setCheckoutOptions(
            allowedTimeOptions.slice(
                allowedTimeOptions.indexOf(checkin) + 1,
                allowedTimeOptions.indexOf(LATER_CHECKOUT_ALLOWED) + 1
            )
        );
        // eslint-disable-next-line
    }, [checkin]);

    useEffect(() => {
        setCheckinOptions(
            allowedTimeOptions.slice(
                allowedTimeOptions.indexOf(EARLIER_CHECKIN_ALLOWED),
                allowedTimeOptions.indexOf(checkout)
            )
        );
        // eslint-disable-next-line
    }, [checkout]);




    const setNewHour = async () => {
        if (edition) {
            const newTime = {
                dayOfWeek,
                startTime: checkin,
                endTime: checkout,
                productId: product?.id,
            };
            await AdminService.createWorkingHours(product?.id, newTime);
            getWorkingHours();
        } else {
            const newTime = {
                dayOfWeek,
                startTime: checkin + ':00',
                endTime: checkout + ':00',
            };
            setWorkingHours((wh) => [...wh, newTime]);
        }
        setCheckin('');
        setCheckout('');
        setDayOfWeek('');
        setShowAddNewTime(false);
    };

    const getWorkingHours = async () => {
        if (edition) {
            const resp = await AdminService.getWorkingHour(product?.id);
            setWorkingHours(resp.data);
        }
    };

    const removeWorkingHourReq = async (workingHour) => {
        if (edition && workingHour?.id) {
            await AdminService.removeWorkingHour(product?.id, workingHour.id);
            getWorkingHours();
        } else {
            setWorkingHours((wh) => {
                const index = wh.indexOf(workingHour);
                let resp = [...wh];
                resp.splice(index, 1);
                return resp;
            });
        }
    };

    useEffect(() => {
        getWorkingHours();
        // eslint-disable-next-line
    }, []);

    const getCurrentMonthDays = () => {
        let selectedMonthNumber = document
            .querySelector('.i_day-picker-body .i_day-picker-row:nth-child(2) div')
            ?.getAttribute('data-date')
            ?.split('-')[0];
        let selectedyear = document
            .querySelector('.i_day-picker-title')
            ?.textContent?.replace(/\D/g, '');
        let daysInMonth = moment(
            `${selectedyear}-${selectedMonthNumber}-01`
        ).daysInMonth();
        let currentTz = momentTimezone.tz.guess();

        let arrDays = [];
        while (daysInMonth) {
            var current = momentTimezone.tz(
                `${selectedyear}-${selectedMonthNumber}-${pad(daysInMonth)} 00:00`,
                currentTz
            );
            const isTodayOrAfter =
                current.isAfter(moment()) ||
                current.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY');
            const isWeekDay =
                current.isoWeekday() !== 6 && current.isoWeekday() !== 7;
            if (isTodayOrAfter && isWeekDay) arrDays.push(current);
            daysInMonth--;
        }
        return arrDays;
    };

    const [isUnlock, setIsUnlock] = useState(true);

    useEffect(() => {
        setLockOrUnlock();
        let monthNavigationButtons = document.getElementsByClassName(
            'e_day-picker-arrow-container'
        );
        for (var i = 0; i < monthNavigationButtons.length; i++)
            monthNavigationButtons[i].addEventListener('click', setLockOrUnlock);

        return () => {
            for (var i = 0; i < monthNavigationButtons.length; i++)
                monthNavigationButtons[i].removeEventListener('click', setLockOrUnlock);
        };
        // eslint-disable-next-line
    }, [availability]);

    const setLockOrUnlock = () => {
        setTimeout(() => {
            let currentMonthDays = getCurrentMonthDays();
            if (currentMonthDays.length > 0) {
                let currentMonthNumber = currentMonthDays[0].month();
                let currentYear = currentMonthDays[0].year();
                let currentMonthAvailableDays = availability.filter(
                    (d) => d.month() === currentMonthNumber && d.year() === currentYear
                );
                //.filter(d => !daysWithBookings.includes(d.format('YYYY-MM-DD')));
                let currentMonthLockedDays =
                    currentMonthDays.length - currentMonthAvailableDays.length;
                currentMonthLockedDays > currentMonthAvailableDays.length
                    ? setIsUnlock(true)
                    : setIsUnlock(false);
            }
        }, 100);
    };

    const unlockCurrentMonth = () => {
        let currentMonthDays = getCurrentMonthDays();
        setAvailability((ad) => [...ad, ...currentMonthDays]);
    };

    const lockCurrentMonth = () => {
        let currentMonthDays = getCurrentMonthDays();
        let lockableDays = currentMonthDays;

        // substract the current month days to the availableDates selected
        let newAvailableDates = (() => {
            let nad = [];
            availability.forEach((ad) => {
                if (
                    lockableDays.filter(
                        (cmd) => cmd.format('DD-MM-YYYY') === ad.format('DD-MM-YYYY')
                    ).length === 0
                )
                    nad.push(ad);
            });
            return nad;
        })();

        setAvailability(newAvailableDates);
    };

    const toggleMonthAvailability = () => {
        isUnlock ? unlockCurrentMonth() : lockCurrentMonth();
        setIsUnlock((isUnlock) => !isUnlock);
    };

    return (
        <Box style={{ width: '100%' }}>
            <div >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Calendar
                                selected={availability}
                                DayComponent={<PositionDay />}
                                onChange={setAvailabilityData}
                                className='calendario'
                                isMultiple={true}
                            />
                            <Button
                                onClick={toggleMonthAvailability}
                                style={{
                                    color: '#00D1BF',
                                    fontFamily: 'Poppins',
                                    textTransform: 'none',
                                }}
                            >
                                {isUnlock
                                    ? 'Desbloquear todo el mes'
                                    : 'Bloquear todo el mes'}
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            style={{
                                marginTop: '20px',
                                overflowY: 'scroll',
                                maxHeight: '350px',
                                width: '90%',
                            }}
                        >
                            {workingHours
                                .sort((a, b) => a.dayOfWeek - b.dayOfWeek)
                                .map((item) => (
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            alignItems: 'center',
                                            borderRadius: '0.5px',
                                            justifyContent: 'space-between',
                                            marginTop: '20px',
                                            paddingBottom: 10,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            borderBottom: '1px solid lightgrey',
                                        }}
                                        key={`key_${`whId_${item.dayOfWeek +
                                            item.startTime +
                                            item.endTime}`}`}
                                    >
                                        <Box>
                                            <h1
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                    width: isMobile ? 'auto' : 100,
                                                }}
                                            >
                                                {daysList[item.dayOfWeek - 1]?.day}
                                            </h1>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box>
                                                <span style={{ fontSize: '16px' }}>
                                                    {item.startTime?.slice(
                                                        0,
                                                        item.startTime?.lastIndexOf(':')
                                                    )}
                                                </span>
                                            </Box>
                                            <Box> - </Box>
                                            <Box>
                                                <span style={{ fontSize: '16px' }}>
                                                    {item.endTime?.slice(
                                                        0,
                                                        item.startTime?.lastIndexOf(':')
                                                    )}
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box style={{ cursor: 'pointer' }}>
                                            <RemoveCircleIcon
                                                onClick={() => removeWorkingHourReq(item)}
                                            />
                                        </Box>
                                    </Box>
                                ))}
                        </Box>
                        {showAddNewTime ? (
                            <Box
                                style={{
                                    order: 3,
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column' : 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                    margin: 10,
                                }}
                            >
                                <Box
                                    style={{
                                        width: isMobile ? '100%' : '25%',
                                        margin: '10px 10px 0 0',
                                        marginBottom: '0',
                                    }}
                                >
                                    <InputLabel id='dayOfWeek-label'>
                                        Dia de la Semana
                                    </InputLabel>
                                    <Select
                                        id='dayOfWeek'
                                        value={dayOfWeek}
                                        labelId={'dayOfWeek-label'}
                                        style={{ width: '100%' }}
                                        onChange={(e) => setDayOfWeek(e.target.value)}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        {daysList.map((d) => (
                                            <MenuItem key={`dayOfTheWeek_${d.id}`} value={d.id}>
                                                {d.day}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>

                                <Box
                                    style={{
                                        width: isMobile ? '100%' : '20%',
                                        margin: '10px 10px 0 0',
                                        marginBottom: '0',
                                    }}
                                >
                                    <InputLabel id='checkin-label'>
                                        Horario Desde
                                    </InputLabel>
                                    <Select
                                        id='checkInTime'
                                        value={checkin}
                                        style={{ width: '100%' }}
                                        labelId={'checkin-label'}
                                        onChange={(e) => setCheckin(e.target.value)}
                                    >
                                        {checkinOptions.map((ht) => (
                                            <MenuItem key={`ht_${ht}`} value={ht}>
                                                {moment(ht, ['HH:mm']).format('hh:mm A')}{' '}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>

                                <Box
                                    style={{
                                        width: isMobile ? '100%' : '20%',
                                        margin: '10px 10px 0 0',
                                    }}
                                >
                                    <InputLabel id='checkout-label'>
                                        Horario Hasta
                                    </InputLabel>
                                    <Select
                                        id='checkOutTime'
                                        style={{ width: '100%' }}
                                        value={checkout}
                                        labelId={'checkout-label'}
                                        onChange={(e) => setCheckout(e.target.value)}
                                    >
                                        {checkoutOptions.map((ht) => (
                                            <MenuItem key={`ht_${ht}`} value={ht}>
                                                {moment(ht, ['HH:mm']).format('hh:mm A')}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box
                                    style={{
                                        width: isMobile ? '100%' : '25%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box style={{ width: '100%' }}>
                                        <Button
                                            variant="contained"
                                            disabled={!dayOfWeek || !checkin || !checkout}
                                            onClick={() => setNewHour()}
                                            style={{
                                                color:
                                                    !dayOfWeek || !checkin || !checkout
                                                        ? 'grey'
                                                        : 'white',
                                                fontFamily: 'Poppins',
                                                marginTop: 8,
                                                height: 38,
                                                textTransform: 'none',
                                            }}
                                        >
                                            Confirmar
                                        </Button>
                                    </Box>
                                    <Box style={{ width: '100%' }}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setShowAddNewTime(false)}
                                            style={{
                                                color: 'blue',
                                                fontFamily: 'Poppins',
                                                marginTop: 8,
                                                height: 38,
                                                textTransform: 'none',
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 8,
                                }}
                            >
                                <Button
                                    onClick={() => setShowAddNewTime(true)}
                                    style={{
                                        color: '#00D1BF',
                                        fontFamily: 'Poppins',
                                        height: 38,
                                        textTransform: 'none',
                                    }}
                                >
                                    Agregar horario
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </div>

            {Object.keys(errors).length > 0 && (
                <Box style={{ color: 'red', textAlign: 'center', margin: 10 }}>
                    Campos con errores
                </Box>
            )}
        </Box>
    );
}

export default Availability;

const pad = (d) => (d < 10 ? '0' + d.toString() : d.toString());

const getStyle = function ({ date, isSelected }) {
    return `${isSelected ? 'o_selected-day' : ''} ${date.type}-day`;
};

const getInline = ({ isToday, isInThePast }) => ({
    cursor: isInThePast ? 'not-allowed' : 'inherit',
    background: isInThePast ? '#e4e4e4' : 'inherit',
    color: isInThePast ? '#555555' : 'inherit',
});

const isFromSelectedMonth = (date) => date.type === 'month';

const PositionDay = (props) => {
    const { date, isSelected, isInThePast, label } = props;

    const onClick = (e) => {
        if (isInThePast) e.stopPropagation();
    };

    return (
        <div
            className={
                isSelected || !isFromSelectedMonth(date) ? '' : 'diagonal-container'
            }
            id='availabilityEditCalendar'
            onClick={onClick}
        >
            <div
                className={getStyle(props)}
                style={{
                    ...getInline(props),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        fontSize: '14px',
                        color: !isFromSelectedMonth(date) ? '#383839' : '',
                        opacity: !isFromSelectedMonth(date) ? '0.5' : 'auto',
                    }}
                >
                    {label}
                </div>
            </div>
        </div>
    );
};
