import React, { useEffect, useState } from "react";
import usePagination from "@mui/material/usePagination/usePagination";
import AdminService from "../../services/AdminService";
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

const Colaborators = ({ hidenMenu, user }) => {
  const [colaborators, setColaborators] = useState([]);
  const [countColaborators, setCountColaborators] = useState(0);
  const [loading, setLoading] = useState(false);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(0, 100);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - countColaborators) : 0;

  const fetchProvidersColab = async () => {
    setLoading(true);
    AdminService.getStore(user?.ownedStore?.code)
      .then((data) => {
        const filterProvidersStatus = data?.data?.data[0].providers?.filter(
          (provider) => provider.status !== "OWNER"
        );
        setColaborators(filterProvidersStatus);
        setCountColaborators(data?.data?.metadata?.count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handlePatchColaborator = async (id, status) => {
    setLoading(true);
    AdminService.patchColaborator(id, status)
      .then((data) => {
        fetchProvidersColab();
        data && setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProvidersColab();
  }, []);

  const styles = {
    chipActive: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      padding: 1,
      borderRadius: 5,
      boxShadow: 1,
      border: 2,
      borderColor: "green",
      width: "70px",
      height: "18px",
    },
    chipDelete: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      padding: 1,
      borderRadius: 5,
      boxShadow: 1,
      border: 2,
      borderColor: "red",
      width: "70px",
      height: "18px",
    },
    chipPending: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      padding: 1,
      borderRadius: 5,
      boxShadow: 1,
      border: 2,
      borderColor: "orange",
      width: "70px",
      height: "18px",
    },
  };
  console.log("colaborators.length", colaborators.length);

  return (
    <Box>
      <h1>
        Colaboradores
        {user ? `de ${user.name || user.email} ${user.lastName || ""}` : ""}
      </h1>
      <Divider sx={{ backgroundColor: "#4B599E", marginBottom: 5 }} />

      {loading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {colaborators.length > 0 ? (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#F8F7F7",
                        //border: "1px solid black",
                      }}
                    >
                      <TableCell>ID</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Estado</TableCell>

                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {colaborators.map((c) => (
                      <TableRow
                        key={c.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>#{c.id} </TableCell>
                        <TableCell>{c?.name}</TableCell>
                        <TableCell>{c?.email}</TableCell>
                        <TableCell>
                          <Box
                            sx={
                              c?.status === "ACTIVE"
                                ? styles.chipActive
                                : c?.status === "PENDING"
                                ? styles.chipPending
                                : styles.chipDelete
                            }
                          >
                            <Typography sx={{ fontSize: 11 }}>
                              {c?.status}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="space-evenly"
                          >
                            <Tooltip title="Editar" arrow>
                              <ToggleButtonGroup
                                size="small"
                                exclusive={true}
                                value={c?.status}
                                //defaultValue={c?.status}
                                defaultChecked={c?.status}
                                onChange={(event, newAlignment) => {
                                  handlePatchColaborator(c?.id, newAlignment);
                                }}
                                aria-label="Small sizes"
                              >
                                <ToggleButton
                                  color="success"
                                  value="ACTIVE"
                                  key="left"
                                >
                                  Activo
                                </ToggleButton>
                                ,
                                <ToggleButton
                                  color="warning"
                                  value="PENDING"
                                  key="center"
                                >
                                  Pendiente
                                </ToggleButton>
                                ,
                                <ToggleButton
                                  color="error"
                                  value="DELETED"
                                  key="right"
                                >
                                  Eliminado
                                </ToggleButton>
                                ,
                              </ToggleButtonGroup>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}

                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={countColaborators}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "2.0rem",
                }}
                variant="h3"
                gutterBottom
                component="div"
              >
                Sin colaboradores asignados
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Colaborators;
