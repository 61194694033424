import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Checkbox } from '@mui/material';

import { useState, useEffect } from 'react';
import AdminService from '../../services/AdminService';
import { labels } from '../../labels';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip({ setUser, user }) {

  const [languages, setlanguages] = useState([]);

  const getLanguages = async () => {
    try {
      const resp = await AdminService.getLanguages();
      setlanguages(resp?.data || []);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    setUser(u => ({ ...u, languages: value }));
  };


  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <>
      <InputLabel id="langs-label">Idiomas</InputLabel>
      <Select
        labelId="langs-label"
        id="langs-select"
        multiple
        style={{ width: '100%' }}
        value={user.languages}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value + "l"} label={labels.languages[languages.find(l => l.id === value)?.name]} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {languages.map((l) => (
          <MenuItem
            key={l.id + "-" + l.id}
            value={l.id}
          >
            <Checkbox checked={user.languages.includes(l.id)} />
            {labels.languages[l.name]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
