import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Avatar, Box, Toolbar } from "@mui/material";

const LogoImage = (props) => <img src={'https://i.imgur.com/CC0SyKS.png'} style={{ maxHeight: props.maxHeight }} alt="" />;

const NavBar = () => {
    const { isAuthenticated, user, isLoading } = useAuth0();

    return <AppBar style={{ backgroundColor: '#ffffff', zIndex: 9999 }} position="sticky">
        <Toolbar>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }} >
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => window.location.href = '/users'}>
                    <LogoImage maxHeight="40px" />
                    <Box style={{ color: 'black', fontSize: 34, fontWeight: 'bold', marginLeft: 10 }}>Bukest ADMIN</Box>
                </Box>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!isLoading &&
                        <div style={{ display: 'flex' }}>
                            {isAuthenticated && <div style={{ display: 'flex', justifyContent: 'center', marginRight: '10px' }}>
                                <Avatar alt={user.name} style={{ width: '40px', height: '40px' }} src={user.picture} />
                            </div>}
                            {/*isAuthenticated && <LogoutButton />*/}
                        </div>
                    }
                </div>
            </div>

        </Toolbar>
    </AppBar >;
};

export default NavBar;